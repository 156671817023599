import { useEffect } from "react";

// images
import icon from '../../img/logo.png';
import bg1 from '../../img/home/bg-1.webp';
import mustashow from '../../img/home/mustashow.webp';
import mustachioPathfinders from '../../img/home/mustachio-pathfinders.png';
import rocket from '../../img/home/rocket.webp';
import dan from '../../img/home/dan.webp';

function Home(props) {
    useEffect(function() {

    });

    return (
        <div className="home bg-white">
            {/* Navbar*/}
            <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white py-2 tw-border-b-[#000000] tw-border-b-[1px]">
                <div className="container">
                    <a className="navbar-brand d-flex align-items-center" href="https://creativelegazpi.ph">
                        <img src={icon} className="tw-h-[53px]" alt="Creative Legazpi" />
                    </a>

                    <div className="d-flex d-lg-none">
                        <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    </div>

                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav align-items-center">
                            <li className="nav-item font-size-90 font-size-xl-100 px-2 px-xl-3">
                                <a className="nav-link text-black font-size-110 mt-3 mt-lg-0" href="#">Collections</a>
                            </li>
                            <li className="nav-item font-size-90 font-size-xl-100 px-2 px-xl-3">
                                <a className="nav-link text-black font-size-110" href="#">Artist Launchpad</a>
                            </li>
                            <li className="nav-item font-size-90 font-size-xl-100 px-2 px-xl-3">
                                <a className="nav-link text-black font-size-110" href="#">Artists</a>
                            </li>
                            <li className="nav-item font-size-90 font-size-xl-100 ps-2 pe-1 ps-xl-3 pe-xl-2">
                                <a className="nav-link font-size-110" href="https://facebook.com/creativelegazpi" target="_blank" rel="noreferrer">
                                    <i className="fa-brands fa-facebook text-black tw-[red] font-size-190"></i>
                                </a>
                            </li>
                            <li className="nav-item font-size-90 font-size-xl-100 mb-2 mb-lg-0">
                                <a className="nav-link font-size-110" href="https://instagram.com/creativelegazpi" target="_blank" rel="noreferrer">
                                    <i className="fa-brands fa-instagram-square text-black tw-[red] font-size-190"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div className="background-image-cover tw-pt-[79px]" style={{backgroundImage: `url(${bg1})`}}>
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center tw-min-h-[calc(100vh-79px)] py-5">
                        <div className="py-5 mb-5">
                            <p className="text-white text-center font-size-140 font-size-sm-140 font-size-md-180 font-size-lg-200 font-size-xl-220 font-size-xxl-230 mb-3 mb-md-0">Creative Legazpi's</p>
                            <p className="text-white text-center font-weight-700 line-height-120 font-size-360 font-size-sm-350 font-size-md-450 font-size-lg-600 font-size-xl-700 font-size-xxl-750">Artist Launchpad</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-5">
                <div className="row justify-content-center align-items-center py-5">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-4 mb-5 mb-md-0">
                        <img src={mustashow} className="w-100" alt="Creative Legazpi" />
                    </div>

                    <div className="col-md-6 col-lg-8 ps-4 ps-lg-5">
                        <div className="text-center text-md-start">
                            <p className="font-size-120 font-size-lg-130 font-size-xl-150 font-weight-500 mb-3">Featured Collection</p>
                            <p className="font-size-160 font-size-lg-230 font-size-xl-260 font-weight-700">The Mustachios: First NFT Tales</p>
                            <p className="font-size-120 font-size-lg-130 font-size-xl-150 font-weight-700 mb-4">by Boii Mustache</p>

                            <p className="font-size-110 mb-4">Boii Mustache, the artist behind the Mustachios, describes them as 100 mustached explorers living in a hidden, mysterious, and magical island called MustachioVerse.</p>
                            <p className="font-size-110 mb-5 pb-lg-2">Each of these Mustachios lived and thrived for over a hundred years, waiting for their turn to tell their own unique tale of adventure.</p>

                            <div className="">
                                <a href="https://medium.com/ownlyio/three-reasons-why-you-should-mint-a-mustachio-now-5ddbf4424d3d" target="_blank" rel="noreferrer" className="btn btn-custom-1 tw-rounded-[30px] px-5 tw-pb-[10px]">Learn more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <img src={mustachioPathfinders} className="w-100" />
            </div>

            <div className="bg-color-1 py-5">
                <div className="container py-5">
                    <div className="text-center mb-5 mt-3">
                        <img src={rocket} className="tw-w-[220px] d-inline-block" alt="Creative Legazpi" />
                    </div>

                    <div className="row justify-content-center mb-5">
                        <div className="col-sm-11 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                            <div className="text-center">
                                <p className="font-size-160 font-size-lg-230 font-size-xl-260 font-weight-700 mb-4">The Legazpi Artist Launchpad</p>
                                <p className="font-size-110 mb-0">Nestled in the vibrant landscape of this artistic haven, our platform is a tribute to the rich cultural tapestry and dynamic artistic spirit of Legazpi's community. We are dedicated to illuminating the unique voices of local artists, showcasing their extraordinary talents, and providing a gateway for their art to reach a global audience. Our mission is deeply rooted in the belief that the artists of Legazpi City possess a unique flair and perspective that deserves to be celebrated and shared with the world.</p>
                            </div>
                        </div>
                    </div>

                    <div className="text-center">
                        <button className="btn btn-custom-1 tw-rounded-[30px] px-5 tw-pb-[10px]">Join the community</button>
                    </div>
                </div>
            </div>

            <div className="row align-items-center mx-0">
                <div className="col-md-6 col-lg-7 order-1 order-md-0">
                    <div className="d-flex justify-content-end w-100 py-5">
                        <div className="md:tw-max-w-[calc((720px/12)*6)] lg:tw-max-w-[calc((960px/12)*7)] xl:tw-max-w-[calc((1140px/12)*7)] xxl:tw-max-w-[calc((1320px/12)*7)] py-5 py-md-0 py-lg-5">
                            <div className="text-center text-md-start pe-md-3 pe-lg-5">
                                <p className="font-size-120 font-size-lg-130 font-size-xl-150 font-weight-500 mb-3">Featured Artist</p>
                                <p className="font-size-160 font-size-lg-230 font-size-xl-260 font-weight-700">Dan Vincent Barotilla</p>
                                <p className="font-size-120 font-size-lg-130 font-size-xl-150 font-weight-700 mb-4">“I'm excited to join Artist Launchpad, a community passionate about pioneering excellence in contemporary art.”</p>

                                <p className="font-size-110 mb-3">Dan Vincent Barotilla is an artist that admits he doesn’t have any art style because his painting technique constantly evolves. Born and raised in Legazpi City, he cites Salvador Dali, Rodel Tapaya, and Ronald Ventura as some of the artists that he look up to.</p>
                                <p className="font-size-110 mb-5 pb-lg-2">One interesting fact about Barotilla is how he discovered his love for painting. He flipped through a calendar with paintings of national art competition winners for students and that prompted him to start his career as an artist. With his passion and skills, he won 1st place in an on-the-spot painting competition during a local festival in the province.</p>

                                <div className="">
                                    <a href="https://opensea.io/collection/mustachioverse" target="_blank" rel="noreferrer" className="btn btn-custom-2 tw-rounded-[30px] px-5 tw-pb-[10px] me-sm-1 me-md-0 me-lg-1 tw-w-[217px] mb-2 mb-sm-0 mb-md-2 mb-lg-0">View Collections</a>
                                    <a href="https://www.artstation.com/sphinx1232" target="_blank" rel="noreferrer" className="btn btn-custom-3 tw-rounded-[30px] px-5 tw-pb-[10px] me-sm-1 ms-md-0 ms-lg-1 tw-w-[217px]">Learn more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-5 px-0 order-0 order-md-1">
                    <div className="background-image-cover tw-min-h-[calc(100vh-79px)]" style={{backgroundImage: `url(${dan})`}}></div>
                </div>
            </div>

            <div className="bg-color-2 py-5">
                <div className="container py-5">
                    <p className="text-center font-size-160 font-size-lg-230 font-size-xl-260 font-weight-700 text-white mb-5">The Process</p>

                    <div className="row">
                        <div className="col-md-4 md:tw-mt-[50px] mb-5 mb-md-0">
                            <div className="text-center mb-4">
                                <div className="d-inline-block">
                                    <div className="tw-bg-[#F7CE00] rounded-circle d-flex justify-content-center align-items-center tw-w-[90px] tw-h-[90px]">
                                        <div className="font-size-160 font-size-lg-230 font-size-xl-260 font-weight-700 tw-text-[#161616]">1</div>
                                    </div>
                                </div>
                            </div>

                            <p className="text-center text-white font-size-120 px-lg-4 mb-2">Apply to Artist Launchpad</p>
                            <p className="text-center text-white font-size-110 px-lg-4 mb-0">Begin by submitting your application. Tell us about your art, your inspirations, and your aspirations. Our application process is simple yet thorough, ensuring every artist gets a fair chance to shine.</p>
                        </div>

                        <div className="col-md-4 md:tw-mt-[120px] mb-5 mb-md-0">
                            <div className="text-center mb-4">
                                <div className="d-inline-block">
                                    <div className="tw-bg-[#D7EDBD] rounded-circle d-flex justify-content-center align-items-center tw-w-[90px] tw-h-[90px]">
                                        <div className="font-size-160 font-size-lg-230 font-size-xl-260 font-weight-700 tw-text-[#161616]">2</div>
                                    </div>
                                </div>
                            </div>

                            <p className="text-center text-white font-size-120 px-lg-4 mb-2">Get Featured</p>
                            <p className="text-center text-white font-size-110 px-lg-4 mb-0">Once part of our community, you have the opportunity to be featured on our platform. Selected artists will have their work showcased in a special section, receiving spotlight attention.</p>
                        </div>

                        <div className="col-md-4">
                            <div className="text-center mb-4">
                                <div className="d-inline-block">
                                    <div className="tw-bg-[#23D8D4] rounded-circle d-flex justify-content-center align-items-center tw-w-[90px] tw-h-[90px]">
                                        <div className="font-size-160 font-size-lg-230 font-size-xl-260 font-weight-700 tw-text-[#161616]">3</div>
                                    </div>
                                </div>
                            </div>

                            <p className="text-center text-white font-size-120 px-lg-4 mb-2">Connect & Grow</p>
                            <p className="text-center text-white font-size-110 px-lg-4 mb-0">Network with fellow artists, engage with art lovers, and participate in exclusive events. Our platform is not just about showcasing art; it's about growing as an artist and expanding your horizons.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-5">
                <div className="row py-5">
                    <div className="col-lg-5 mb-5 mb-lg-0">
                        <p className="text-center text-lg-start font-size-250 font-size-lg-350 font-size-xl-400 line-height-130">Frequently<br/> Asked<br/> <span className="font-weight-700">Questions</span></p>
                    </div>

                    <div className="col-lg-7">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item" style={{borderLeft:'none',borderRight:'none',borderWidth:'2px'}}>
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button font-size-130" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                        What is Creative Legazpi's Artist Launchpad?
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show"
                                     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Creative Legazpi's Artist Launchpad is a platform dedicated to supporting and showcasing the talents of artists from Legazpi City and beyond. We provide opportunities for artists to exhibit their work, connect with a community, and gain exposure in the contemporary art world.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" style={{borderLeft:'none',borderRight:'none',borderWidth:'2px'}}>
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed font-size-130" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                            aria-expanded="false" aria-controls="collapseTwo">
                                        How can I apply to be featured on Creative Legazpi's Artist Launchpad?
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse"
                                     aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Artists can apply through our online submission form. Please include your personal details, a brief biography, a description of your artistic style, and a portfolio of your work. Our selection committee reviews applications regularly.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" style={{borderLeft:'none',borderRight:'none',borderWidth:'2px'}}>
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed font-size-130" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                            aria-expanded="false" aria-controls="collapseThree">
                                        Is there a fee to join Creative Legazpi's Artist Launchpad?
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse"
                                     aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Currently, there is no fee to apply or to be featured on Creative Legazpi's Artist Launchpad. Our goal is to support artists by providing a free platform for exposure and connection.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" style={{borderLeft:'none',borderRight:'none',borderWidth:'2px'}}>
                                <h2 className="accordion-header" id="heading-4">
                                    <button className="accordion-button collapsed font-size-130" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse-4"
                                            aria-expanded="false" aria-controls="collapse-4">
                                        What type of art do you showcase?
                                    </button>
                                </h2>
                                <div id="collapse-4" className="accordion-collapse collapse"
                                     aria-labelledby="heading-4" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        We showcase a wide range of art, including paintings, sculptures, digital art, photography, and mixed media. Our focus is on creativity and quality, regardless of the medium.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" style={{borderLeft:'none',borderRight:'none',borderWidth:'2px'}}>
                                <h2 className="accordion-header" id="heading-5">
                                    <button className="accordion-button collapsed font-size-130" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse-5"
                                            aria-expanded="false" aria-controls="collapse-5">
                                        Can international artists apply?
                                    </button>
                                </h2>
                                <div id="collapse-5" className="accordion-collapse collapse"
                                     aria-labelledby="heading-5" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Yes, while we have a special focus on artists from Legazpi City, we welcome and encourage international artists to apply and be part of our global artistic community.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" style={{borderLeft:'none',borderRight:'none',borderWidth:'2px'}}>
                                <h2 className="accordion-header" id="heading-6">
                                    <button className="accordion-button collapsed font-size-130" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse-6"
                                            aria-expanded="false" aria-controls="collapse-6">
                                        How often do you feature new artists?
                                    </button>
                                </h2>
                                <div id="collapse-6" className="accordion-collapse collapse"
                                     aria-labelledby="heading-6" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        We feature new artists monthly. This allows us to give ample spotlight to each featured artist and provide a diverse range of art to our audience.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" style={{borderLeft:'none',borderRight:'none',borderWidth:'2px'}}>
                                <h2 className="accordion-header" id="heading-7">
                                    <button className="accordion-button collapsed font-size-130" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse-7"
                                            aria-expanded="false" aria-controls="collapse-7">
                                        What opportunities does Creative Legazpi's Artist Launchpad provide for featured artists?
                                    </button>
                                </h2>
                                <div id="collapse-7" className="accordion-collapse collapse"
                                     aria-labelledby="heading-7" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        Featured artists receive exposure on our website and social media channels, opportunities to connect with other artists and art professionals, and potential participation in online exhibitions and events.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" style={{borderLeft:'none',borderRight:'none',borderWidth:'2px'}}>
                                <h2 className="accordion-header" id="heading-8">
                                    <button className="accordion-button collapsed font-size-130" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse-8"
                                            aria-expanded="false" aria-controls="collapse-8">
                                        Can I purchase art from Creative Legazpi's Artist Launchpad?
                                    </button>
                                </h2>
                                <div id="collapse-8" className="accordion-collapse collapse"
                                     aria-labelledby="heading-8" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        While Creative Legazpi's Artist Launchpad primarily focuses on showcasing artists, any sales inquiries can be directed to the artists themselves. We facilitate connections between artists and potential buyers.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" style={{borderLeft:'none',borderRight:'none',borderWidth:'2px'}}>
                                <h2 className="accordion-header" id="heading-9">
                                    <button className="accordion-button collapsed font-size-130" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse-9"
                                            aria-expanded="false" aria-controls="collapse-9">
                                        How can I stay updated with Creative Legazpi's Artist Launchpad's events and features?
                                    </button>
                                </h2>
                                <div id="collapse-9" className="accordion-collapse collapse"
                                     aria-labelledby="heading-9" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        You can subscribe to our newsletter, follow us on social media, or regularly check our website for the latest news, artist features, and event information.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" style={{borderLeft:'none',borderRight:'none',borderWidth:'2px'}}>
                                <h2 className="accordion-header" id="heading-10">
                                    <button className="accordion-button collapsed font-size-130" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse-10"
                                            aria-expanded="false" aria-controls="collapse-10">
                                        Who can I contact for more information?
                                    </button>
                                </h2>
                                <div id="collapse-10" className="accordion-collapse collapse"
                                     aria-labelledby="heading-10" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        For more inquiries, please contact us at hello@creativelegazpi.ph. Our team is always ready to assist with any questions you may have.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-black py-5">
                <div className="container py-5">
                    <p className="text-center text-white font-size-180 tw-font-[700] mb-0">Subscribe to Our Newsletter for Exclusive Updates!</p>
                    <p className="text-center text-white font-size-110 mb-4">Stay in the loop with the latest news, special offers, and insider insights.</p>

                    <div className="row justify-content-center">
                        <div className="col-md-7 col-lg-6 col-xl-4">
                            <input type="text" className="form-control text-center mb-4" name="email" placeholder="Email Address" />

                            <div className="text-center">
                                <button className="btn btn-custom-2 tw-rounded-[30px] px-5 tw-pb-[8px] mb-2 mb-sm-0 mb-md-2 mb-lg-0">SUBSCRIBE&nbsp;NOW</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-5">
                <div className="row justify-content-between align-items-center">
                    <div className="col-6">
                        <a href="#" className="d-inline-block">
                            <img src={icon} className="tw-h-[53px]" alt="Creative Legazpi" />
                        </a>
                    </div>

                    <div className="col-6">
                        <div className="d-flex justify-content-end">
                            <div className="pe-4">
                                <a className="font-size-110" href="https://facebook.com/creativelegazpi" target="_blank" rel="noreferrer">
                                    <i className="fa-brands fa-facebook text-black tw-[red] font-size-190"></i>
                                </a>
                            </div>
                            <div className="">
                                <a className="font-size-110" href="https://instagram.com/creativelegazpi" target="_blank" rel="noreferrer">
                                    <i className="fa-brands fa-instagram-square text-black tw-[red] font-size-190"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home